<template>
  <section class="container carbon">
    <AppPaintGraphic class="carbon__bg"/>
    <h2 class="text-title carbon__title">Carbon-offset</h2>
    <p class="text-xlarge carbon__p">
        Through our partnership with sustainability platform,
        <a class="text-link" target="_blank" rel="noopener" href="https://www.aerial.is">Aerial</a>,
        all NFTs made available by
        <a class="text-link" target="_blank" rel="noopener" href="https://cosmicpaws.io">Cosmic Paws</a>
        are carbon-offset.</p>
    <div class="carbon__columns">
      <iframe
        class="carbon__badge"
        frameborder="0"
        src="https://www.aerial.is/nft/embed?address=0x1D9B23e9A2d1185429206E2eB572bd70ED8dca60&theme=dark"
      />
      <p class="carbon__p">Aerial is a sustainability platform that calculates the carbon emissions
        based on associated energy use. The offsets will be allocated to high-quality
        forest conservation projects verified by American Carbon Registry and to
        emerging technologies such as Charm industrial that captures carbon from
        biomass waste and permanently stores it underground.</p>
    </div>
  </section>
</template>

<script>
import AppPaintGraphic from './AppPaintGraphic.vue';

export default {
  name: 'carbon',
  components: {
    AppPaintGraphic
  }
}
</script>

<style scoped lang="scss">

@import '../styles/vars';
@import '../styles/mixins';

.container {
  background: var(--color-2);
  color: var(--color-4);
  position: relative;
  overflow: hidden;
}

.carbon__title,
.carbon__p {
  position: relative;
  z-index: 1;
}

.carbon__bg {
  position: absolute;
  transform: rotate(-120deg) scale(-1, 1);
  right: -30vw;
  top: -5vw;
  width: 90vw;
  @include media(3-up) {
    right: -32vw;
    top: -10vw;
    width: 75vw;
  }
}

.carbon__title + .carbon__p {
  @include media(3-up) {
    margin-bottom: 60px;
  }
}

.carbon__columns {
  margin-top: -30px;
  @include media(3-up) {
    margin-top: 0;
    display: grid;
    grid-template-columns: minmax(260px, 340px) auto;
  }
}

.carbon__badge {
  height: auto;
  margin: 0;
  max-height: 100px;
}
.carbon__badge + .carbon__p {
  max-width: none;
  margin: 0;
  padding: 0;
  border: none;
}

a.text-link {
  color: var(--color-3);
  &::before {
    background: var(--color-4);
  }
}

</style>
