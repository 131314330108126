<template>
  <section class="section-hero">
    <AppPaintGraphic class="section-hero__bg"/>
    <h1
      ref="h1"
      class="section-hero__title font-display">
      <span>Long Neckie Fellas</span>
      <span>&nbsp;Long Neckie Fellas</span>
    </h1>
    <div class="container section-hero__cta">
      <a
        @click="goToGenerator"
        class="section-hero__cta-button"
        href="#generator"
      >Start Here</a>
    </div>
    <picture class="section-hero__img">
      <source
        type='image/webp'
        :srcset='image.webp'/>
      <source
        type='image/png'
        :srcset='image.png'/>
      <img
        alt="Long Neck Fella"
        width="1438"
        height="2222"/>
    </picture>
  </section>
</template>

<script>
import AppPaintGraphic from './AppPaintGraphic.vue';
import {mapGetters} from 'vuex';
import gsap from 'gsap';

export default {
  name: 'section-hero',
  components: {
    AppPaintGraphic
  },
  computed: {
    ...mapGetters({
      theme: 'theme'
    }),
    image() {
      return {
        png: require(`@/assets/long-neck-${this.theme}.png`),
        webp: require(`@/assets/long-neck-${this.theme}.webp`),
      };
    }
  },
  methods: {
    setScrollingMarquee() {
      const {h1} = this.$refs;
      const span = h1.firstElementChild;
      gsap
        .timeline({ repeat: -1 })
        .fromTo(h1, { x: 0 }, {
          x: -span.offsetWidth,
          ease: 'none',
          duration: 60
        });
    },
    goToGenerator(event) {
      event.preventDefault();
      const gen = document.querySelector('section.generator');
      window.scrollTo({
        top: gen.offsetTop,
        behavior: 'smooth'
      })
    }
  },
  mounted() {
    this.setScrollingMarquee();
  }
}
</script>

<style scoped lang="scss">

@import '../styles/vars';
@import '../styles/mixins';

.section-hero {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.section-hero__bg {
  position: absolute;
  bottom: -15vw;
  right: 0;
  object-fit: cover;
  z-index: 0;
  height: 100vh;
  width: auto;
  left: -100px;
  @include media(3-up) {
    left: -15vw;
    bottom: -10vh;
    width: 115vw;
    height: auto;
  }
}

.section-hero__cta {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 3;
  @include media(3-down) {
    padding: var(--ui-gutter);
  }
}

.section-hero__cta-button {
  text-decoration: none;
  color: var(--color-4);
  font-family: $font-regular-bold;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 0.15s ease-in-out;
  padding: 0 60px;
  background: var(--color-5);
  border: 4px solid var(--color-6);
  @include base-font(h5);
  width: 100%;

  @media (hover: hover) {
    &:hover {
      background: var(--color-4);
      color: var(--color-5);
    }
  }

  @include media(3-up) {
    width: auto;
  }
}

.section-hero__title {
  @include fluid-type(242px, 512px);
  line-height: 1;
  white-space: nowrap;
  position: absolute;
  left: 0;
  color: var(--color-1);
  user-select: none;
  margin: 0;
  z-index: 0;
  mix-blend-mode: overlay;
  span {
    display: inline-block;
  }
}

.section-hero__img {
  position: absolute;
  bottom: 0;
  width: 80%;
  height: 85%;
  right: 0;
  z-index: 2;
  user-select: none;
  @include media(3-up) {
    width: 60%;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
    object-position: 50% 100%;
  }
}
</style>
