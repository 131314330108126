<template>
  <AppNav/>
  <router-view />
  <AppFooter/>
</template>

<script>
import AppNav from './components/AppNav.vue';
import AppFooter from './components/AppFooter.vue';
import {mapGetters} from 'vuex';

export default {
  name: 'App',
  components: {
    AppNav,
    AppFooter
  },
  computed: {
    ...mapGetters({
      theme: 'theme'
    })
  },
  mounted() {
    this.$store.dispatch('setRandomTheme');
    document.documentElement.classList.add(`theme-${this.theme}`);
    window.setTimeout(() => document.querySelector('main').classList.add('ready'), 250)
  }
};
</script>

<style lang='scss'>
  @import './styles/index.scss';
</style>
