<template>
  <figure class="app-logo">
    <svg
      class="app-logo__svg"
      viewBox="0 0 256 256">
      <circle cx="128" cy="128" r="128"/>
      <path d="M150.8,47.1c-15-3.5-8.6-1.9-0.3,3.1c11.9,9.4,18,28.8,11.8,44c14.2-4.1,2.9,25.5-4.6,22.4 c-0.4,6.3-2.4,10.6-6.1,14.4c-6.6,24.1-1,56.1,6.1,78C233.9,181.3,229.3,68.5,150.8,47.1z M110.7,45.9c0-0.2-0.3-0.1-0.4-0.1 C110.2,46,110.5,45.8,110.7,45.9z M91.2,87.5c1,0.2,2.4,2.1,1.4-0.1c-4.7-17.5-0.8-34.1,15.7-40.9C66.7,52,26.7,110.1,48.5,160.4 c6.7,18.8,26.4,39.4,45.2,46.8c15.1,8.9,10.6-4.4,13.3-16.5c3.5-32.7-5.3-61-17.9-83.7C86.1,103.6,80.3,84.7,91.2,87.5z  M120.6,92.9c-5.7-2.4-20.9-5.3-22.6,2.8c1.8,11,8.5,16.8,19.7,11.2C120.2,103.3,127.3,96.2,120.6,92.9z M151.3,92.3 c-14.8-3.8-32.7,0.8-17.3,15.6C144.8,113.5,156.1,103.2,151.3,92.3z"/>
      <path
        id="app-logo__text-path"
        d="M228,128c0,55.2-44.8,100-100,100S28,183.2,28,128S72.8,28,128,28S228,72.8,228,128z"
      />
      <text>
        <textPath
          xlink:href="#app-logo__text-path"
          startOffset="0%"
        >
          <tspan>Long • Neckie • Fellas •</tspan>
        </textPath>
      </text>
    </svg>
  </figure>
</template>

<script>
export default {
  name: 'app-logo'
}
</script>

<style scoped lang="scss">

@import '../styles/vars';
@import '../styles/mixins';

.app-logo {
  display: inline-block;
  margin: 0;

  svg {
    overflow: visible;
  }

  circle {
    transform: scale(1.2);
    transform-origin: 50% 50%;
  }

  tspan {
    font-size: 2em;
    text-transform: uppercase;
    font-family: $font-compact;
    letter-spacing: 0.420em;
    @include media(2-up) {
      letter-spacing: 0.375em;
    }
    @include media(3-up) {
      letter-spacing: 0.350em;
    }
    @include media(4-up) {
      letter-spacing: 0.315em;
    }
    @include media(5-up) {
      letter-spacing: 0.245em;
    }
    @include media(6-up) {
      letter-spacing: 0.375em;
      font-size: 1.5em;
    }
    @include media(7-up) {
      letter-spacing: 0.365em;
      font-size: 1.45em;
    }
  }

  #app-logo__text-path {
    fill: none;
    animation: rotate 30s linear infinite;
    transform-origin: 50% 50%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

</style>
