import { createStore } from 'vuex'

export default createStore({
  state: {
    theme: 1
  },
  mutations: {
    SET_THEME(state, theme) {
      state.theme = theme;
    }
  },
  actions: {
    setRandomTheme({ commit }, ) {
      const totalThemes = 6;
      const themes = Array.from({ length: totalThemes }, (_, i) => i + 1);
      const randomIndex = Math.floor(Math.random() * themes.length);
      const randomTheme = themes[randomIndex];
      commit('SET_THEME', randomTheme);
    }
  },
  getters: {
    theme: state => state.theme,
  }
})
