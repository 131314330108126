<template>
  <nav class="app-nav__links">
    <a target="_blank" rel="noopener" class="app-nav__link font-ui" href="https://twitter.com/NylaCollection">Twitter</a>
    <a target="_blank" rel="noopener" class="app-nav__link font-ui" href="https://opensea.io/collection/long-neckie-fellas">OpenSea</a>
    <a target="_blank" rel="noopener" class="app-nav__link font-ui" href="https://discord.com/invite/BpZgZ7rX8y">Discord</a>
  </nav>
</template>

<script>
export default {
  name: 'app-nav__links'
}
</script>
