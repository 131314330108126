<template>
  <footer class="app-footer">
    <AppPaintGraphic :style="2"/>
    <div class="app-footer__columns">
      <div class="app-footer__column">
        <h4 class="app-footer__title font-regular">Follow</h4>
        <AppNavLinks/>
      </div>
      <div class="app-footer__column">
        <h4 class="app-footer__title font-regular">Legal</h4>
        <router-link
          class="font-ui"
          to="/privacy-policy">
          Privacy Policy
        </router-link>
        <router-link
          class="font-ui"
          to="/terms-of-service">
          Terms of Service
        </router-link>
      </div>
      <router-link to="/">
        <AppLogo/>
      </router-link>
    </div>
    <div class="app-footer__gutter">
      <p>Powered by
        <a
          class="text-link"
          href="https://www.cosmicpaws.io"
          target="_blank"
          rel="noopener">CosmicPaws.io</a>.
      </p>
      <p>©{{currentYear}} Long Neckie Fellas®</p>
    </div>
  </footer>
</template>

<script>
import AppLogo from './AppLogo.vue';
import AppNavLinks from './AppNavLinks.vue';
import AppPaintGraphic from './AppPaintGraphic.vue';

export default {
  name: 'app-footer',
  components: {
    AppLogo,
    AppNavLinks,
    AppPaintGraphic
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear();
    },
  }
}
</script>

<style lang="scss">

@import '../styles/mixins';
@import '../styles/helpers';

.app-footer {
  background: var(--color-4);
  color: var(--color-2);
  margin: 0;
  padding: 45px var(--ui-gutter);
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @include media(3-up) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  @include section-border();

  p > a {
    color: inherit;
    text-decoration: none;
    font: inherit;
  }
}

.app-footer .app-paint-graphic {
  position: absolute;
  top: 0;
  right: -200%;
  bottom: 0;
  height: 115%;
  min-height: inherit;
  pointer-events: none;
  z-index: 0;
  @include media(3-up) {
    right: -20%;
  }
}

.app-footer__title {
  margin-bottom: 1em;
}

.app-footer .app-nav__links {
  text-decoration: none;
  display: inline-block;
  display: flex;
  flex-direction: column;
}

.app-footer__columns {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  @include media(3-up) {
    flex-direction: row;
  }
}

.app-footer__column {
  min-width: 40%;
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media(3-up) {
    margin-bottom: 0;
  }
  a {
    color: var(--color-5);
    text-decoration: none;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:hover {
      color: var(--color-3);
    }
  }
}

.app-footer .app-logo {
  width: 128px;
  height: 128px;
  margin: 0 auto 30px;
  @include media(3-up) {
    margin: 30px 0 0;
  }
  path {
    fill: var(--color-2);
  }
  circle {
    fill: var(--color-4);
  }
  tspan {
    fill: var(--color-5)
  }
}

.app-footer__gutter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  @include media(3-up) {
    flex-direction: row;
  }
}
</style>
