<template>
  <section class="container section-messaging">
    <h2 class="text-title section-messaging__title">SALE OVER!</h2>
    <p class="text-large">
      <span>Long Neckie Fellas </span> available only on <a style="color: var(--color-3)" href="https://opensea.io/collection/long-neckie-fellas">OpenSea</a>
      <a>. Love and cherish your LNF. Afterall you were the one that created him here.</a>
    </p>
  </section>
</template>

<script>
export default {
  name: 'section-messaging'
}
</script>

<style scoped lang="scss">

@import '../styles/mixins';

.section-messaging {
  background: var(--color-4);
  color: var(--color-2);
  position: relative;
  z-index: 1;

  @include section-border();
}

.section-messaging__title {
  color: var(--color-3);
}

</style>
