<template>
  <nav class="app-nav">
    <router-link to="/">
      <AppLogo/>
    </router-link>
    <AppNavLinks/>
  </nav>
</template>

<script>
import AppLogo from './AppLogo.vue';
import AppNavLinks from './AppNavLinks.vue';

export default {
  name: 'app-nav',
  components: {
    AppLogo,
    AppNavLinks
  }
}
</script>

<style lang="scss">

@import '../styles/vars';
@import '../styles/mixins';

.app-nav {
  top: 20px;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0 var(--ui-gutter);
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 10;
  @include media(3-up) {
    top: 60px;
    align-items: center;
    height: var(--nav-h);
    justify-content: flex-start;
  }
  .app-logo {
    width: calc(var(--nav-h) * 0.66);
    height: calc(var(--nav-h) * 0.66);
    margin-right: calc(var(--nav-h) * 0.66);
    path {
      fill: var(--color-3);
    }
    circle {
      fill: var(--color-2);
    }
    tspan {
      fill: var(--color-3)
    }
  }
  .app-nav__links {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-top: 5px;
    @include media(3-up) {
      padding-top: 0;
      align-items: center;
      flex-direction: row;
    }
  }
  .app-nav__link {
    color: currentColor;
    text-decoration: none;
    &:not(:last-child) {
      margin-bottom: 15px;
      @include media(3-up) {
        margin-right: 80px;
        margin-bottom: 0;
      }
    }
  }
}

</style>
