<template>
  <article>
    <SectionHero/>
    <SectionMission/>
    <SectionCarbon/>
    <!-- <AppUserInstructions/> -->
  </article>
</template>

<script>
import SectionHero from '@/components/SectionHero.vue';
import SectionMission from '@/components/SectionMission.vue';
//import SectionGenerator from '@/components/SectionGenerator/Generator.vue';
import SectionCarbon from '@/components/SectionCarbon.vue';
// import AppUserInstructions from '@/components/AppUserInstructions.vue';

export default {
  name: 'Home',
  components: {
    SectionHero,
    SectionMission,
    //SectionGenerator,
    SectionCarbon
  }
}
</script>
